import React from "react"
import logo from "../images/logo.svg";

const Footer = () => (
    <footer className="text-gray-600 body-font">
        <div className="px-10 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
            <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900" href="/">
                    <img className="h-8 w-auto sm:h-10" alt="logo" src={logo}/>
                </a>
                <p className="mt-2 text-sm text-gray-500">Trapunte, materassi, piumini, biancheria per la casa.</p>
            </div>
            <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                    <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3 uppercase">Indirizzo</h2>
                    <div className="flex justify-center lg:justify-start">
                        <svg className="w-5 h-5 inline mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                        </svg>
                        <nav className="list-none mb-10">
                            <li>
                                <span className="text-gray-600 hover:text-gray-800">
                                    Via del Forno 3, Pianzano
                                </span>
                            </li>
                            <li>
                                <span className="text-gray-600 hover:text-gray-800">31010 Godega di San'Urbano (TV)</span>
                            </li>
                        </nav>
                    </div>
                </div>
                <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                    <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3 uppercase">Orari di apertura</h2>
                    <nav className="list-none mb-10">
                        <li>
                            <span className="text-gray-600 hover:text-gray-800">
                                <svg className="w-5 h-5 inline mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                                Lun. ven: 8:30 - 12:00 / 15:00 - 19:00
                            </span>
                        </li>
                        <li>
                            <span className="text-gray-600 hover:text-gray-800">
                                <svg className="w-5 h-5 inline mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                                Sabato: 8:30 - 12:00 (pomeriggio su prenotazione)
                            </span>
                        </li>
                    </nav>
                </div>
                <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                    <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3 uppercase">Email</h2>
                    <nav className="list-none mb-10">
                        <li>
                            <span className="text-blue-500">
                                <svg className="w-5 h-5 inline mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"/>
                                </svg>
                                <a href="mailto:info@trapuntificiocasini.it">info@trapuntificiocasini.it</a>
                            </span>
                        </li>
                    </nav>
                    <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3 uppercase">Telefono</h2>
                    <nav className="list-none mb-10">
                        <li>
                            <span className="text-gray-600 hover:text-gray-800">
                                <svg className="w-5 h-5 inline mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                                </svg>
                                <a href="tel: +39 0438 430273">+39 0438 430273</a>
                            </span>
                        </li>
                    </nav>
                </div>
            </div>
        </div>
        <div className="bg-gray-100">
            <div className="container mx-auto py-6 px-5 flex flex-wrap flex-col sm:flex-row">
                <p className="text-gray-500 text-sm text-center sm:text-left">© 2021 Trapuntificio Casini —
                    <span className="text-gray-600 ml-1">Partita IVA: 00336890264</span>
                </p>
                <div className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                    <a className="text-gray-500" rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/Trapuntificiocasinipianzano/">
                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </footer>
)


export default Footer
